import { HttpClient, baseApiPath, BaseAirportStore } from '@wings/shared';
import { apiUrls } from './ApiUrls';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { AlertStore } from '@uvgo-shared/alert';
import {
  AirportCustomGeneralModel,
  CustomsContactModel,
  CustomsDetailInfoModel,
  IntlCustomsDetailsModel,
  UsCustomsDetailsModel,
} from '../Models';
import {
  IAPIAirportCustomGeneralRequest,
  IAPIAirportCustomsContact,
  IAPIAirportCustomsContactRequest,
  IAPIIntlCustomsDetails,
  IAPIUsCustomsDetailsRequest,
} from '../Interfaces';
import { Logger } from '@wings-shared/security';
import { IAPIPageResponse } from '@wings-shared/core';

export class AirportCustomDetailStore extends BaseAirportStore {
  /* istanbul ignore next */
  public getCustomsGeneralInfo(airportId: number): Observable<AirportCustomGeneralModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    return http.get<AirportCustomGeneralModel>(`api/${airportId}/${apiUrls.customGeneralInfo}`).pipe(
      Logger.observableCatchError,
      map(response => AirportCustomGeneralModel.deserialize(response)),
      catchError(err => throwError(err))
    );
  }
  /* istanbul ignore next */
  public upsertGeneral(request: IAPIAirportCustomGeneralRequest): Observable<AirportCustomGeneralModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIAirportCustomGeneralRequest> = isNewRequest
      ? http.post<IAPIAirportCustomGeneralRequest>(`api/${request.airportId}/${apiUrls.customGeneralInfo}`, request)
      : http.put<IAPIAirportCustomGeneralRequest>(
        `api/${request.airportId}/${apiUrls.customGeneralInfo}/${request.id}`,
        request
      );

    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => AirportCustomGeneralModel.deserialize(response)),
      tap(() => AlertStore.info(`General Information ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }

  /* istanbul ignore next */
  public getCustomsNonUsInfo(airportId: number): Observable<IntlCustomsDetailsModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    return http.get<IntlCustomsDetailsModel>(`api/airport/${airportId}/${apiUrls.intlCustomsInfo}`).pipe(
      Logger.observableCatchError,
      map(response => IntlCustomsDetailsModel.deserialize(response)),
      catchError(err => throwError(err))
    );
  }

  public upsertIntlCustomsInfo(request: IntlCustomsDetailsModel): Observable<IntlCustomsDetailsModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIIntlCustomsDetails> = isNewRequest
      ? http.post<IAPIIntlCustomsDetails>(
        `api/airport/${request.airportId}/${apiUrls.intlCustomsInfo}`,
        request.serialize()
      )
      : http.put<IAPIIntlCustomsDetails>(
        `api/airport/${request.airportId}/${apiUrls.intlCustomsInfo}/${request.id}`,
        request.serialize()
      );

    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => IntlCustomsDetailsModel.deserialize(response)),
      tap(() => AlertStore.info(`Non US Customs Details ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }

  /* istanbul ignore next */
  public getCustomsDetail(airportId: number): Observable<CustomsDetailInfoModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    return http.get<CustomsDetailInfoModel>(`api/airport/${airportId}/${apiUrls.customsDetail}`).pipe(
      Logger.observableCatchError,
      map(response => CustomsDetailInfoModel.deserialize(response)),
      catchError(err => throwError(err))
    );
  }
  public getCustomsUsInfo(airportId: number): Observable<UsCustomsDetailsModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    return http.get<UsCustomsDetailsModel>(`api/airport/${airportId}/${apiUrls.usCustomsInfo}`).pipe(
      Logger.observableCatchError,
      map(response => UsCustomsDetailsModel.deserialize(response)),
      catchError(err => throwError(err))
    );
  }

  public upsertCustomsDetail(request: CustomsDetailInfoModel): Observable<CustomsDetailInfoModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIIntlCustomsDetails> = isNewRequest
      ? http.post<IAPIIntlCustomsDetails>(
        `api/airport/${request.airportId}/${apiUrls.customsDetail}`,
        request.serialize()
      )
      : http.put<IAPIIntlCustomsDetails>(
        `api/airport/${request.airportId}/${apiUrls.customsDetail}/${request.id}`,
        request.serialize()
      );

    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => CustomsDetailInfoModel.deserialize(response)),
      tap(() => AlertStore.info(`Customs Details ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }

  public upsertUsCustomsInfo(request: UsCustomsDetailsModel): Observable<UsCustomsDetailsModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIUsCustomsDetailsRequest> = isNewRequest
      ? http.post<IAPIUsCustomsDetailsRequest>(
        `api/airport/${request.airportId}/${apiUrls.usCustomsInfo}`,
        request.serialize()
      )
      : http.put<IAPIUsCustomsDetailsRequest>(
        `api/airport/${request.airportId}/${apiUrls.usCustomsInfo}/${request.id}`,
        request.serialize()
      );

    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => UsCustomsDetailsModel.deserialize(response)),
      tap(() => AlertStore.info(`US Customs Details ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }

  public getCustomsContacts(): Observable<IAPIPageResponse<CustomsContactModel>> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    return http.get<IAPIPageResponse<IAPIAirportCustomsContact>>(`${apiUrls.customsContact}`).pipe(
      Logger.observableCatchError,
      map(response => ({ ...response, results: CustomsContactModel.deserializeList(response.results) })),
      catchError(err => throwError(err))
    );
  }

  public upsertCustomsContact(request: CustomsContactModel): Observable<CustomsContactModel> {
    const http: HttpClient = new HttpClient({ baseURL: baseApiPath.airports });
    const isNewRequest: boolean = request.id === 0;
    const upsertRequest: Observable<IAPIAirportCustomsContactRequest> = isNewRequest
      ? http.post<IAPIAirportCustomsContactRequest>(`${apiUrls.customsContact}`, request.serialize())
      : http.put<IAPIAirportCustomsContactRequest>(`${apiUrls.customsContact}/${request.id}`, request.serialize());

    return upsertRequest.pipe(
      Logger.observableCatchError,
      map(response => CustomsContactModel.deserialize(response)),
      tap(() => AlertStore.info(`Customs Contacts ${isNewRequest ? 'created' : 'updated'} successfully!`))
    );
  }
}
